footer {
    background-color: $footer-bg;
    transition: padding-left .5s;
	color:$white;
	padding: 2rem;
	position: fixed;
	bottom: 0;
    width: 100%;
    @include breakpoint (ipad-max) {
        display: none;
    }
    a {
        color: $white;
        text-decoration: none;
        font-size: 1.2rem;
        font-family: $light-font;
        &:hover {
            color: $white;
            text-decoration: none;
        }
    }
}