main {
    height: calc(100vh - 56px);
    transition: padding-left .5s;
    position: relative;
    @include breakpoint(ipad-max) {
        height: 100vh;
        // margin-top: 7rem;
    }
}
@font-face {
    font-family: 'emboss-font';
    src: url('../webFont/Afterglow-Regular.ttf');
}
.main-slider {
    .slick-slide {
        height: calc(100vh - 56px);
        outline: none;
        @include breakpoint(ipad-max) {
            height: calc(100vh) !important;
            overflow: hidden; 
            padding-top: 7rem;
        }
        img {
            object-fit: cover;
        }
    }
    .slick-dots {
        bottom: 1rem;
        li {
            width:1rem;
            height:1rem;
            background:transparent;
            -webkit-transition:all 0.5s;
            transition:all 0.5s;
            border-radius:50%;
            border:1px solid $white;
            margin:0 0.35rem;
            cursor:pointer;
            button {
                width:1rem;
                height:1rem;
                &:before {
                    content: '';
                    width: 1rem;
                    height: 1rem;
                }
            }
            &.slick-active
             {    
                background:$white;
            }
        }
    }
    .slick-prev {
        left: 3rem;
        transform: scale(-1);
        transform-origin: center center;
        top: 45.5%;
        height: auto;
        z-index: 1;
    }
    .slick-next {
        right: 3rem;
        z-index: 1;
        height: auto;
    }   

    &__content {
        &--center {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 0 !important;
            height: calc(100vh - 56px);
            @include breakpoint(ipad-max) {
                height: auto;
                padding-top: 2rem;
                justify-content: flex-start;
            }
        }
    }
    &__slide2 {
        @include breakpoint (ipad-max) {
            .row,
            [class*=col-],
            .container {
                height: calc(100vh - 7rem)
            }
            background-image: url(../images/banner2-mob.png);
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right bottom;
        }
    }
    &__slide5 {
        @include breakpoint (ipad-max) {
            .row,
            [class*=col-],
            .container {
                height: calc(100vh - 7rem);
            }
            // background-image: url(../images/about_gallary-mob.png);
            // background-size: 260px;
            
        }
        .about-me-bg-image {
            background-image: url(../images/about_gallary-mob.png);
            background-repeat: no-repeat;
            background-position: right bottom;
            @media (min-width:320px) and (max-width:575px) {
                background-size: 70%;
            }
            @media (min-width:576px) and (max-width:991px) {
                background-size: contain;
            }
        }
    }
    &__slide3 {
        @include breakpoint (ipad-max) {
            .row {
                align-items: center;
            }
            .container {
                display: flex;
                justify-content: center;
                flex-direction: column;
                height: calc(100vh - 7rem)
            }
        }
    }
}
.typed_wrap {
    font-size: 7rem;
    color: $white;
    font-weight: 700;
    line-height: normal;
    @include breakpoint(ipad-max) {
        font-size: 4rem;
    }
}
.typed::after { 
    content: '|';
    display: inline;
    -webkit-animation: blink 0.7s infinite;
    -moz-animation: blink 0.7s infinite;
    animation: blink 0.7s infinite;
}
/*Removes cursor that comes with typed.js*/
.typed-cursor {
    opacity: 0;
    display: none;
}
/*Custom cursor animation*/
@keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@-webkit-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
@-moz-keyframes blink {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
.detail-menu {
    margin-bottom: 0;
    li {
        @include breakpoint (ipad-max) {
            margin: 2rem 0;
            &:first-child {
                margin-top: 5rem;
            }
        }
        @media (min-width:320px) and (max-width:580px) {
            &:first-child {
                margin-top: 0;
            }
        }
        a {
            font-size: 2.7rem;
            line-height: 7.2rem; 
            color: $white;
            font-weight: 300;
            font-family: $base-font;
            text-decoration: none;
            outline: none;
            transition: all 0.2s ease-in-out;
            @include breakpoint(ipad-max) {
                font-size: 2.2rem;
                line-height: 2.4rem;
            }
            &:hover {
                transform-origin:bottom left;
                transform:scale(1.2);
            }
            &:focus {
                outline: none;
            } 
        }
    }
}
.slick-active .slide-content {
    animation-name: fadeInUpSD;
    animation-delay: 1s;
    // opacity: 1;
  }
@keyframes fadeInUpSD {
0% {
    opacity: 0;
    transform: translateX(-100px);
}
100% {
    opacity: 1;
    transform: translate(0);
}
}
@for $i from 1 to 10 {
    .slide-content:nth-child(#{$i}) { animation-duration: $i * 0.25s; }
}
.nourishables {
    &__title {
        font-size: 3.2rem;
        line-height: 2.7rem;
        color: $white;
        font-weight: 600;
        font-family: $base-font;
        margin-bottom: 0;
        @include breakpoint(ipad-max) {
            font-size: 2.2rem;
        }
    }
    &__img-block {
        @include breakpoint (ipad-max) {
            margin-top:3rem;
        }
    }
    &__sub-title {
        font-size: 2.7rem;
        color: $white;
        font-weight: 300;
        font-family: $base-font;
        margin: 1rem 0;
        @include breakpoint(ipad-max) {
            font-size: 1.7rem;
        }
    }
    &__text-pic {
        margin-bottom: 3rem;
        @include breakpoint(ipad-max) {
            margin-bottom: 1rem;
            img {
                width: 70%;
            }
        }
    }
}
.aboutus {
    &__img-cont {
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint (ipad-max) {
            display: none;
        }
    }
    .aboutus-accordion {
        .card {
            background-color: transparent;
            border: none;
            width: 100%;
            margin-bottom: 1rem;
            .card-header {
                border-bottom: none;
                background-color: transparent;
                width: 100%;
            }
            .card-body {
                width: 100%;
                margin-top: 2rem;
                @include breakpoint (portrait-mobile-max) {
                    margin-top: 1rem;
                }
            }
        }
        &__title {
            font-size: 4rem;
            color: $white;
            font-weight: 300;
            font-family: $base-font;
            position: relative;
            display: flex;
            align-items: center;
            @include breakpoint (ipad-max) {
                font-size: 3rem;
                white-space: nowrap;
            }
            &::after {
                position: absolute;
                content: '';
                left: 0;
                bottom: -2rem;
                border-bottom: 2px solid $border-bottom;
                width: calc(100% - 83%);
                @include breakpoint(mobile-max) {
                    bottom: -1rem;
                    width: calc(100% - 50%);
                }
            }
            .accordion-icon {
                font-size: 1.5rem;
                margin-top: 1rem;
                margin-left: 10rem;
                color: #59544e; 
                @include breakpoint (mobile-max) {
                    display: none;
                }
            }
            &[data-toggle="collapse"]{
                &[aria-expanded="true"] {
                    >.accordion-icon {
                        -webkit-transform: rotate(90deg);
                        -moz-transform:    rotate(90deg);
                        -ms-transform:     rotate(90deg);
                        -o-transform:      rotate(90deg);
                        transform:         rotate(90deg);
                    }
                }
            }
        }
        &__content {
            font-size: 2rem;
            color: $white;
            font-weight: 300;
            font-family: $base-font;
            @include breakpoint (portrait-mobile-max) {
                font-size: 1.4rem;
            }
        }
        .aboutus-data {
            max-height: 20rem;
            overflow-y: auto;
            overflow-x:hidden;
            @include breakpoint (ipad-max) {
                max-height: 35rem;
            }
            &::-webkit-scrollbar {
                width: 0.5rem;
            }
            &::-webkit-scrollbar-track {
                // -webkit-box-shadow: inset 0 0 6px #8b8d79; 
                background-color: #8b8d79;
                border-radius: 0.8rem;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0.8rem;
                // -webkit-box-shadow: inset 0 0 6px #b4b5a6; 
                background-color: #b4b5a6;
            }
        }
        .brand-container {
            max-height: 17.5rem;
            overflow-y: auto;
            overflow-x:hidden;
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(11%, 1fr));
            grid-gap: 1rem;   
            align-items: center;
            padding-right: 1.5rem;
            @include breakpoint (portrait-mobile-max) {
                max-height: calc(100vh - 7rem);
                grid-template-columns: repeat(auto-fill, minmax(46%, 1fr));
            }
            @media (min-width:577px) and (max-width:991px) {
                max-height: calc(100vh - 7rem);
                grid-template-columns: repeat(auto-fill, minmax(22%, 1fr));
            }
            &::-webkit-scrollbar {
                width: 0.5rem;
            }
            &::-webkit-scrollbar-track {
                // -webkit-box-shadow: inset 0 0 6px #8b8d79; 
                background-color: #8b8d79;
                border-radius: 0.8rem;
            }
            &::-webkit-scrollbar-thumb {
                border-radius: 0.8rem;
                // -webkit-box-shadow: inset 0 0 6px #b4b5a6; 
                background-color: #b4b5a6;
            }
        }
        .gallery-slider {
            .slick-slide {
                height: 100%;
                margin-right: 3rem;
                &:last-child {
                    margin-right: 0;
                }
                @include breakpoint (ipad-max) {
                    height: auto !important;
                    padding-top: 1.5rem; 
                }
            }
        }
    }
    .nav-tabs {
        display: none;
    }
    @include breakpoint (mobile-max) {
        .nav-tabs {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            white-space: nowrap;  
            border-bottom: 0;
            position: relative;
            .leftarrow {
                position: absolute;
                left: -12px;
                height: 1.5rem;
                top: 50%;
                transform: translate(0,-50%);
            }
            & .nav-link {
                border: transparent;
                padding: 0;
                margin-right: 3rem;
                margin-left: 1rem;
                color: $white;
                font-weight: 300;
                padding-bottom: 0.5rem;
                border-bottom: 1px solid transparent;
                font-size: 1.4rem;
                @include breakpoint (portrait-mobile-max) {
                    margin-right: 1rem;
                }
                &:focus {
                    outline: none;
                }
                &.active {
                    background-color: transparent;
                    color: $white;
                    border-bottom: 1px solid #bbbbbb;
                    font-weight: 600;
                }  
            }
        }
        .tab-content {
            margin-top: 1rem;
        }
        .card {
            border: none;
        }
    
        .card .card-header {
            // display:none;
        }  
    
        .card .collapse{
            display:block;
        }
    }
    @include breakpoint (ipad-min) {
        .tab-content > .tab-pane {
            display: block !important;
            opacity: 1;
        }
    }
}
.atoznutrition {
    .slick-prev {
        display: none !important;
    }
    .slick-slide {
        @include breakpoint (ipad-max) {
            height: auto !important;
        }
    }
    .slick-next {
        z-index: 1;
        top: 99%;
        transform: rotate(92deg);
        left: 50%;
        width: 1rem;
    }
    .main-title {
        margin-bottom: 0;
        // position: relative;
        // &:after {
        //     position: absolute;
        //     content: '';
        //     left: 0;
        //     bottom: -2rem;
        //     border-bottom: 2px solid $border-bottom;
        //     width: calc(100% - 83%);
        // }
    }
    &__girl-img {
        display: flex;
        justify-content: center;
        align-items: center;
        @include breakpoint(ipad-max) {
            margin-top: 2.5rem;
        }
    }
    &__main-content {
        height: 100%;
        display: flex;
        justify-content: center;
        // align-items: center;
        flex-direction: column;
    }
    &__sub-title {
        font-size: 2rem;
        line-height: 29px;
        color: $white;
        font-weight: 300;
        font-family: $base-font;
        margin-bottom: 2rem;
        &:nth-child(1) {
            margin-top: 3rem;
        }
    }
    &__details {
        display: grid !important;
        grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));
        grid-gap: 1.5rem;
        width: 100%;
        padding-top: 0 !important;
        @media (min-width:320px) and (max-width:480px){
            grid-template-columns: repeat(auto-fit, minmax(4rem, 1fr));  
            grid-gap: 0.5rem;
        }
        @include breakpoint(ipad-max) {
            height: auto !important;
        }
        &__list-item {
            padding: 1.5rem;
            height: 17.4rem;
            display: flex;
            justify-content: center;
            align-items: center;
            @include breakpoint (portrait-mobile-max) {
                height: auto;
                padding: 1rem 0.5rem;
            }
            &--brown {
                background-color: #454733;
                color: #b7b7b7;
                position: relative;
                hr {
                    background-color: #838477;
                }
            }
            &--maroon {
                background-color: #433d39;
                color: #6e6a67;
                position: relative;
                hr {
                    background-color: #8f8b88;
                }
            }
        }
        &__slide-cont {
            width: 100%; 
        }
        &__wrapper {
            text-align: center;
            width: 100%;
        }
        &__initial {
            font-size: 7rem;
            line-height: 6rem;
            font-family: 'emboss-font';
            text-shadow: -1px -1px rgba(255, 255, 255, 0.9), 1px 1px rgba(0, 0, 0, 0.8); 
        }
        &__name {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }
}
.menu {
    &__secondary-img {
        @include breakpoint(desktop-min) {
            display: flex;
            justify-content: center;
        }
        @include breakpoint(ipad-max) {
            display: none !important;
        }
    }
    .menu-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.contactus {
    position: relative;
    &__content {
        padding: 4rem 7rem;
        width: 55rem;
        height: 35rem;
        font-size: 2rem;
        line-height: 29px;
        color: $white;
        font-weight: 300;
        background-color: #333333;
        font-family: $base-font;
        position: absolute;
        top: 50%;
        left: 30%;
        transform: translate(-50%,-50%);
        @include breakpoint (ipad-max) {
            padding: 2rem;
            width: 100%;
            left: 0;
            transform: translate(0,-50%);
            top: unset;
            bottom: -10rem;
        }
        li {
            display: flex;
            align-items: flex-start;
            margin-bottom: 4rem;
            @include breakpoint (mobile-max) {
                margin-bottom: 1rem;
            }
            &:first-child {
                margin-bottom: 1rem;
                h2 {
                    font-size: 3rem;
                }
            }
            p {
                margin-bottom: 0;
            }
            picture {
                margin-top: 0.8rem;
                margin-right: 0.8rem;
            }
            a {
                color: $white;
                text-decoration: none;
                &:hover {
                    color: $white;
                    text-decoration: none; 
                }
            }
        }
    }
}
.consultwithus {
    .main-title {
        margin-bottom: 2.2rem;
        @include breakpoint (mobile-max) {
            margin-bottom: 1.2rem;
        }
    }
    &__content {
        margin-bottom: 7rem;
        @include breakpoint (mobile-max) {
            margin-bottom: 2rem;
        }
        // @include breakpoint (mobile-min) {
        //     margin-bottom: 1.5rem;
        // }
    }
    &__img-wrap {
        position: relative;
        height: 58rem;
    }
    &__right-img {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: calc(100vh -  56px);
        @include breakpoint (ipad-max){
            height: auto;
        }
    }
    &__img {
        position: absolute;
        &--pc {
            height: auto;
            top: 16%;
            right:0;    
            img {
                width: 35rem;
                @media (min-width:320px) and (max-width:420px) {
                    width: 18rem;
                }
                @media (min-width:421px) and (max-width:580px) {
                    width: 25rem;
                }
            }
        }
        &--rosemary {
            top: 5%;
            right: 55%;
            @media (min-width:320px) and (max-width:420px) {
                right: 40%;
            }
            @media (min-width:421px) and (max-width:580px) {
                right: 40%;   
            }
            img {
                width: 15rem;
                @media (min-width:320px) and (max-width:420px) {
                    width: 10rem;
                }
                @media (min-width:421px) and (max-width:580px) {
                    width: 13rem;
                }
            }
        }
        &--pineapple {
            bottom: 10%;
            left: 10%;
            @media (min-width:320px) and (max-width:420px) {
                top: 40%;
                left: 25%;
                bottom: unset;
            }
            @media (min-width:421px) and (max-width:580px) {
                bottom: 31%;
                left: 18%;
            }
            img {
                width: 11.7rem;
                @media (min-width:320px) and (max-width:420px) {
                    width: 6.7rem;
                }
                @media (min-width:421px) and (max-width:580px) {
                    width: 8.7rem;
                }
            }
        }
        
        &--kiwi {
            bottom: 7%;
            left: -10%;
            @media (min-width:320px) and (max-width:420px) {
                top: 43%;
                left: 8%;  
                bottom: unset;   
            }
            @media (min-width:421px) and (max-width:580px) {
                bottom: 27%;
                left: 0%;
            }
            img {
                width: 11.7rem;
                @media (min-width:320px) and (max-width:420px) {
                    width: 6.7rem;
                }
                @media (min-width:421px) and (max-width:580px) {
                    width: 8.7rem;
                }
            }
        }
        &--book {
            top: 30%;
            right: 70%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media (min-width:320px) and (max-width:420px) {
                top: 20%;
                right: 55%;
            }
            @media (min-width:421px) and (max-width:580px) {
                top: 23%;
                right: 62%;
            }
            img {
                width: 17.5rem;
                @media (min-width:320px) and (max-width:420px) {
                    width: 10rem;
                }
                @media (min-width:421px) and (max-width:580px) {
                    width: 13.5rem;
                }
                + img {
                    width: 100%;
                    @media (min-width:320px) and (max-width:420px) {
                        width: 2rem;
                    }
                    @media (min-width:421px) and (max-width:580px) {
                        width: 2.5rem;
                    }
                }
            }
        }
    }
}
.foodeducation {
    .slick-slide {
        height: auto !important;
        padding: 0;
        @include breakpoint (ipad-max) {
            padding: 0 !important;
        }
    }
    .slick-arrow {
        width: 1rem;
        height: auto;
    }
    .slick-next {
        &--icon {
            right: -2rem;
            @include breakpoint (ipad-max) {
                right: 2rem !important;
                z-index: 99999999;
            }
        }
    }
    .slick-prev {
        transform: unset !important; 
        &::before {
            content:'';
        }
        &--icon {
            left: -2.5rem;
            margin-top: 0.9rem;
            @include breakpoint (ipad-max) {
                left: 1rem !important;
                margin-top: 0.9rem;
                z-index: 99999999;
            }
        }
    }
    .main-title {
        margin-bottom: 4rem;
        position: relative;
        &::after {
            position: absolute;
            content: '';
            left: 0;
            bottom: -2rem;
            border-bottom: 2px solid $border-bottom;
            width: calc(100% - 70%);
        }
        @include breakpoint (ipad-max) {
            font-size: 3rem;
            margin-bottom: 2rem;
            &::after {
                bottom: -1rem;
            }
        }
    }
    .sub-title {
        margin-bottom: 4rem;
        margin-top:0;
        font-size: 2rem;
        font-weight: 300;
        text-transform: none;
        @include breakpoint (portrait-mobile-max){
            font-size: 1.5rem;
        }
        @include breakpoint (ipad-max) {
            margin-bottom: 1rem;
        }
        + .sub-title {
            margin-bottom: 4rem;
            margin-top:0;
            @include breakpoint (portrait-mobile-max){
                font-size: 1.5rem;
            }
            @include breakpoint (ipad-max) {
                margin-bottom: 1rem;
            }
        }
    }
    &__slider {
        width: 35rem;
        height: auto;
        position: relative;
        @include breakpoint(ipad-max) {
            margin-top: 5rem;
        }
        &::before {
            width: 100%;
            content: " ";
            // background-image: url(../images/workshops_border.png);
            // background-repeat: no-repeat;
            // background-size: 100% 100%;
            border: 1px solid #fff;
            position: absolute;
            left: -0.5rem;
            top: -0.5rem;
            height: 100%;     
            z-index: 99;                                               
        }
    }
}
.signinmodal {
    &__input {
        border: none;
        border-bottom: 1px solid $dark-green;
        background-color: transparent;
        outline: none;
        margin-bottom: 3rem;
    }
    &__button-wrap {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 3rem;
        .btn-primary {
            font-weight: 300;
        }
        span {
            font-size: 1.4rem;
            padding: 0 1rem;
        }
    }
    &__social-sign-in {
        width: 100%;
        text-align: center;
        label {
            margin-bottom: 1rem;
        }
    }
    &__social-ico {
        display: flex;
        justify-content: center;
        align-items: center;
        a {    
            margin: 0 0.5rem; 
            font-size: 2rem;
            color: $dark-green;
            text-decoration: none;
            line-height: normal;
            &:last-child {
                font-size: 2.5rem;
            }
            &:hover {
                color: $black;
                text-decoration: none;
            }
        }
    }
}
.books {
    .main-title {
        margin-bottom: 2rem;
        position: relative;
        &::after {
            position: absolute;
            content: '';
            left: 0;
            bottom: -2rem;
            border-bottom: 2px solid $border-bottom;
            width: calc(100% - 70%);
        }
        @include breakpoint (ipad-max) {
            font-size: 3rem;
            margin-bottom: 2rem;
            &::after {
                bottom: -1rem;
            }
        }
    }
    &__subtitle {
        font-size: 2rem;
        line-height: 29px;
        color:$white;
        font-weight: 300;
        margin-bottom: 2rem;
        text-transform:unset;
        max-height: 20rem;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 2rem;
        @include breakpoint (ipad-max) {
            max-height: 15rem;
        }
        &::-webkit-scrollbar {
            width: 0.5rem;
        }
        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px #8b8d79; 
            background-color: #8b8d79;
            border-radius: 1rem;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 1rem;
            // -webkit-box-shadow: inset 0 0 6px #b4b5a6; 
            background-color: #b4b5a6;
        }
        &--pill {
            display: none;
            background-color:$white;
            color: #77583d;
            border-radius: 5rem;
            padding: 1rem 2rem;
            font-size: 1rem;
            font-weight: 700;
            @include breakpoint (ipad-max) {
                display: block;
            }
        }
        @include breakpoint (mobile-max) {
            font-size: 1.4rem;
            line-height: 24px;
        }
        // + .books__subtitle {
        //     font-size: 1.6rem;
        //     text-transform:unset;
        //     @include breakpoint (mobile-max) {
        //         font-size: 1.2rem;
        //     }
        // }
        
        
    }
    &__right,
    &__img-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__img-container {
        // height: calc(100vh - 10rem);
        // height: 80%;
       
        @include breakpoint (large-desktop-min) {
            height: 70%;
        }
        @include breakpoint (ipad-max){
            height: calc(100vh - 50rem);
            margin-top: 2rem;
        }
    }
    @media (min-width:992px) and (max-width:1199px) {
        .books__img-container {
            height: 70%; 
        }
        
    }
    .knowmore-text {
        @include breakpoint (ipad-max) {
            display: none; 
        }
    }
}
.home {
    &__secondary-img {
        @include breakpoint(desktop-min) {
            display: flex;
            justify-content: center;
        }
    }
    @include breakpoint(ipad-max) {
    .main-title {
        font-size: 2rem;
    }
    }
}

@keyframes topBottom {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}
  
  @keyframes moverightleft{
    0% {
      transform:  translate(251px,20px)  rotate(18deg) scaleX(1.00) ;
    }
    100% {
      transform:  translate(39px,-41px)  rotate(NaNdeg) scaleX(0.99) ;
    }
  }
  
  @-moz-keyframes moverightleft{
    0% {
      -moz-transform:  translate(251px,20px)  rotate(18deg) scaleX(1.00) ;
    }
    100% {
      -moz-transform:  translate(39px,-41px)  rotate(NaNdeg) scaleX(0.99) ;
    }
  }
  
  @-webkit-keyframes moverightleft {
    0% {
      -webkit-transform:  translate(251px,20px)  rotate(18deg) scaleX(1.00) ;
    }
    100% {
      -webkit-transform:  translate(39px,-41px)  rotate(NaNdeg) scaleX(0.99) ;
    }
  }
  
  @-o-keyframes moverightleft {
    0% {
      -o-transform:  translate(251px,20px)  rotate(18deg) scaleX(1.00) ;
    }
    100% {
      -o-transform:  translate(39px,-41px)  rotate(NaNdeg) scaleX(0.99) ;
    }
  }
  
  @-ms-keyframes moverightleft {
    0% {
      -ms-transform:  translate(251px,20px)  rotate(18deg) scaleX(1.00) ;
    }
    100% {
      -ms-transform:  translate(39px,-41px)  rotate(NaNdeg) scaleX(0.99) ;
    }
  }
  
  
  @keyframes downtoup{
    0% {
      transform:  translate(7px,149px)  rotate(20deg) ;
    }
    100% {
      transform:  translate(4px,2px)  rotate(-10deg) ;
    }
  }
  
  @-moz-keyframes downtoup{
    0% {
      -moz-transform:  translate(7px,149px)  rotate(20deg) ;
    }
    100% {
      -moz-transform:  translate(4px,2px)  rotate(-10deg) ;
    }
  }
  
  @-webkit-keyframes downtoup {
    0% {
      -webkit-transform:  translate(7px,149px)  rotate(20deg) ;
    }
    100% {
      -webkit-transform:  translate(4px,2px)  rotate(-10deg) ;
    }
  }
  
  @-o-keyframes downtoup {
    0% {
      -o-transform:  translate(7px,149px)  rotate(20deg) ;
    }
    100% {
      -o-transform:  translate(4px,2px)  rotate(-10deg) ;
    }
  }
  
  @-ms-keyframes downtoup {
    0% {
      -ms-transform:  translate(7px,149px)  rotate(20deg) ;
    }
    100% {
      -ms-transform:  translate(4px,2px)  rotate(-10deg) ;
    }
  }



  @keyframes animationFrames{
    0% {
      transform:  translate(242px,83px)  rotate(14deg) ;
      right: -100%;
    }
    100% {
      transform:  translate(-41px,-54px)  rotate(NaNdeg) ;
      right: 70%;
    }
  }
  
  @-moz-keyframes animationFrames{
    0% {
      -moz-transform:  translate(242px,83px)  rotate(14deg) ;
      right: -100%;
    }
    100% {
      -moz-transform:  translate(-41px,-54px)  rotate(NaNdeg) ;
      right: 70%;
    }
  }
  
  @-webkit-keyframes animationFrames {
    0% {
      -webkit-transform:  translate(242px,83px)  rotate(14deg) ;
      right: -100%;
    }
    100% {
      -webkit-transform:  translate(-41px,-54px)  rotate(NaNdeg) ;
      right: 70%;
    }
  }
  
  @-o-keyframes animationFrames {
    0% {
      -o-transform:  translate(242px,83px)  rotate(14deg) ;
      right: -100%;
    }
    100% {
      -o-transform:  translate(-41px,-54px)  rotate(NaNdeg) ;
      right: 70%;
    }
  }
  
  @-ms-keyframes animationFrames {
    0% {
      -ms-transform:  translate(242px,83px)  rotate(14deg) ;
      right: -100%;
    }
    100% {
      -ms-transform:  translate(-41px,-54px)  rotate(NaNdeg) ;
      right: 70%;
    }
  }


.slick-current {
    &.slick-active {
        .consultwithus__img--rosemary {
            animation: topBottom 1s linear;
            transform: translateY(0);
            animation-direction:normal;
        }
        .consultwithus__img--pc {
            animation: moverightleft linear 1.5s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            -webkit-animation: moverightleft linear 1.5s;
            -webkit-animation-iteration-count: 1;
            -webkit-transform-origin: 50% 50%;
            -moz-animation: moverightleft linear 1.5s;
            -moz-animation-iteration-count: 1;
            -moz-transform-origin: 50% 50%;
            -o-animation: moverightleft linear 1.5s;
            -o-animation-iteration-count: 1;
            -o-transform-origin: 50% 50%;
            -ms-animation: moverightleft linear 1.5s;
            -ms-animation-iteration-count: 1;
            -ms-transform-origin: 50% 50%;      
        }
        .consultwithus__img--book {
            animation: animationFrames linear 1s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            -webkit-animation: animationFrames linear 1s;
            -webkit-animation-iteration-count: 1;
            -webkit-transform-origin: 50% 50%;
            -moz-animation: animationFrames linear 1s;
            -moz-animation-iteration-count: 1;
            -moz-transform-origin: 50% 50%;
            -o-animation: animationFrames linear 1s;
            -o-animation-iteration-count: 1;
            -o-transform-origin: 50% 50%;
            -ms-animation: animationFrames linear 1s;
            -ms-animation-iteration-count: 1;
            -ms-transform-origin: 50% 50%;
        }
        .consultwithus__img--kiwi {
            animation: downtoup linear 1.5s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            -webkit-animation: downtoup linear 1.5s;
            -webkit-animation-iteration-count: 1;
            -webkit-transform-origin: 50% 50%;
            -moz-animation: downtoup linear 1.5s;
            -moz-animation-iteration-count: 1;
            -moz-transform-origin: 50% 50%;
            -o-animation: downtoup linear 1.5s;
            -o-animation-iteration-count: 1;
            -o-transform-origin: 50% 50%;
            -ms-animation: downtoup linear 1.5s;
            -ms-animation-iteration-count: 1;
            -ms-transform-origin: 50% 50%;
         }
         .consultwithus__img--pineapple {
            animation: downtoup linear 1s;
            animation-iteration-count: 1;
            transform-origin: 50% 50%;
            -webkit-animation: downtoup linear 1s;
            -webkit-animation-iteration-count: 1;
            -webkit-transform-origin: 50% 50%;
            -moz-animation: downtoup linear 1s;
            -moz-animation-iteration-count: 1;
            -moz-transform-origin: 50% 50%;
            -o-animation: downtoup linear 1s;
            -o-animation-iteration-count: 1;
            -o-transform-origin: 50% 50%;
            -ms-animation: downtoup linear 1s;
            -ms-animation-iteration-count: 1;
            -ms-transform-origin: 50% 50%;
         }
    }
}

.aboutus-mobile {
    display: none;
    @include breakpoint (ipad-max) {
        display: block; 
    }
    .about-me--mob-btn {
        display: inline-block;
        display: inline-block;
        padding: 1rem;
        padding-right: 1rem !important;
    }
    .main-title {
        margin-bottom: 2rem;
        position: relative;
        &::after {
            position: absolute;
            content: '';
            left: 0;
            bottom: -2rem;
            border-bottom: 2px solid $border-bottom;
            width: calc(100% - 70%);
        }
        @include breakpoint (ipad-max) {
            font-size: 3rem;
            margin-bottom: 2rem;
            &::after {
                bottom: -1rem;
            }
        }
    }
    .slide-button {
        display: inline-block;
        display: inline-block;
        padding: 0.5rem;
        text-align: center;
        padding-right: 0.5rem !important;
    }
    .main-slider__content {
        padding: 2rem 1rem 1rem;
    }
    .sub-title {
        font-size: 2rem;
        line-height: 29px;
        color:$white;
        font-weight: 300;
        margin-bottom: 1rem;
        text-transform:unset;
        max-height: 20rem;
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 2rem;
        @include breakpoint (ipad-max) {
            max-height: 15rem;
        }
        &::-webkit-scrollbar {
            width: 0.5rem;
        }
        &::-webkit-scrollbar-track {
            // -webkit-box-shadow: inset 0 0 6px #8b8d79; 
            background-color: #8b8d79;
            border-radius: 1rem;
        }
        &::-webkit-scrollbar-thumb {    
            border-radius: 1rem;
            // -webkit-box-shadow: inset 0 0 6px #b4b5a6; 
            background-color: #b4b5a6;
        }
        @include breakpoint (mobile-max) {
            font-size: 1.4rem;
            line-height: 24px;
        }
    }
}
.main-slider__content--center {
    margin-left: 0 !important;
}
@media (min-width:1690px) {
    .atoznutrition__main-content {
        max-width: 80%;
        margin: auto;
    }
}
