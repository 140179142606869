@media (min-width: 1400px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1140px !important;
    }
}

@media (min-width: 1401px) and (max-width:1920px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1400px !important;
    }
    .img-fluid {
        width: auto;
    }
}


@media (min-width:1400px) and (max-width:1500px) {
    .main-slider__content {
        padding-left: 10rem;
    }
}
@media (min-width:992px) and (max-width:1300px) {
    .main-slider__content {
        padding-left: 3rem;
    }
}