// Fonts
// $primary-font: Arial, Helvetica, sans-serif;

// Color
$base-color: #e23434;
$text-color: #2d2d2d;
$background : #a4a695;
$footer-bg : #333333;
$border-bottom : #cbcbcb;
$dark-green : #535541;
$white : #ffffff;
$black : #000000;

// Container and Grid Spacing
$container-width: 1170px;
$grid-space: 15px;

// path
@font-face {
    font-family: emboss-font;
    src: url('../../webFont/Afterglow-Regular.ttf');
}
$images-path: "../images" !default;
$font-path: "../fonts" !default;
$icomoon-font-family: "icomoon" !default;
$base-font:  'Open Sans', sans-serif;
$light-font: 'Open Sans', sans-serif;
$bold-font: 'Open Sans', sans-serif;
// icomoon
$icomoon-font-family: "icomoon" !default;
$icomoon-font-path: "../fonts/icomoon" !default;

$icon-twitter: "\e900";
$icon-facebook-logo: "\e901";
$icon-instagram: "\e902";
$icon-right-arrow-angle: "\e903";
$icon-google-ico: "\e904";


