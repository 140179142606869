

html {
	font-size: 10px;
	outline: none;
}

body {
	font-family: $base-font;
	font-size: 1rem;
	line-height: 1.4;
	height: calc(100vh - 56px);
	cursor: url("../images/mouse.png"), auto;
	background-color: $background;
	outline: none;
	@include breakpoint (ipad-max) {
		height: auto; 
	}
}
.row,
[class*=col-],
.container {
	height: 100%;
	@include breakpoint (ipad-max) {
		height: auto; 
	}
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
		.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
			border:1px solid $dark-green;
			color: $dark-green;
			background-color: $white;
			box-shadow:none;
		}
.main-title {
	font-size: 4rem;
	font-family: $base-font;
	color: $white;
	font-weight: 300;
	@include breakpoint(ipad-max) { 
		font-size: 3rem;
	}
}
.h-md-auto {
	@include breakpoint(ipad-max) {
        height: auto !important;
    }
}
.sub-title {
	text-transform: uppercase;
	font-family: $base-font;
	color: $white;
	font-weight: 600;
	letter-spacing: 1px;
	font-size: 1.8rem;
	margin-top: 3rem;
	@include breakpoint(ipad-max) {
		font-size: 1.2rem;
		margin-top: 1rem;
    }
	&--withbg {
		padding: 1.2rem;
		background-color: $dark-green;
	}
}

.btn-primary {
	padding: 0.5rem 1.2rem;
	background-color: $dark-green;
	text-transform: uppercase;
	font-family: $base-font;
	color: $white;
	font-weight: 600;
	letter-spacing: 1px;
	font-size: 1.8rem;
	line-height: normal;
	outline: none;
	border: 1px solid transparent;
	border-radius: 0;
	&:focus,
	&:hover,
	.btn-primary:not(:disabled):not(.disabled):active:focus {
		background-color: $white;
		border: 1px solid transparent;
		color: $dark-green;
		box-shadow: none;
	}
	@include breakpoint (mobile-max) {
		font-size: 1.2rem; 
	}

}
.modal-body {
	padding: 6rem 3rem;
	.form-control {
		height: 4rem;
		border: 1px solid transparent;
		background: $white;
		border-radius: 0;
		margin-bottom: 1.5rem;
		background-color: #dddddd;
		font-size: 1.4rem;
		text-transform: uppercase;
		+ .form-control {
			margin-bottom: 1.5rem;
		}
		&:focus {
			box-shadow: none;
			border: 1px solid transparent;
		}
	}
	.btn-primary {
		text-transform: uppercase;
		&:hover,
		&:focus {
			border:1px solid $dark-green;
			color: $dark-green;
			background-color: $white;
			box-shadow:none;
		}
	}
	&--foodedu {
		padding:3rem;
	}
	&--signinmodal {
		padding: 5rem 2rem 3rem 2rem;
		background-color: #dddddd;
		.form-inline {
			label {
				color: #545454;
				font-size: 1.4rem;
			}
		}
	}
}

.image-bottom {
	display: flex;
	justify-content: center;
	align-items: flex-end;
}

.jq-tip-dialog {
	background-color: transparent !important;
	h1 {
		padding: 0;
	}
	@include breakpoint (ipad-max) {
		display: none;
	}
}
.jq-tip-dialog .wrapper>div .jq-tip-content {
	padding: 1rem 2rem !important;
	border-radius: 5rem;
}
.jq-tip-dialog .wrapper>div {
	border-radius: 5rem;
	color: #77583d;
}

* {
	margin: 0;
	padding: 0; 
  }
  
  /* Icon 1 */
  
  .hamburger-menu {
	width: 2rem;
	height: 2rem;
	position: relative;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .5s ease-in-out;
	-moz-transition: .5s ease-in-out;
	-o-transition: .5s ease-in-out;
	transition: .5s ease-in-out;
	cursor: pointer;
  }
  
  .hamburger-menu span {
	display: block;
	position: absolute;
	height: 9px;
	width: 100%;
	border-radius: 9px;
	opacity: 1;
	left: 0;
	-webkit-transform: rotate(0deg);
	-moz-transform: rotate(0deg);
	-o-transform: rotate(0deg);
	transform: rotate(0deg);
	-webkit-transition: .25s ease-in-out;
	-moz-transition: .25s ease-in-out;
	-o-transition: .25s ease-in-out;
	transition: .25s ease-in-out;
  }
  
  .hamburger-menu span:nth-child(1) {
	top: 0;
  }
  
  .hamburger-menu span:nth-child(2) {
	top: 0.6rem;
  }
  
  .hamburger-menu span:nth-child(3) {
	top: 1.2rem;
  }
  
  .hamburger-menu.open span:nth-child(1) {
	background-color: $white;
	top: 0.6rem;
	-webkit-transform: rotate(135deg);
	-moz-transform: rotate(135deg);
	-o-transform: rotate(135deg);
	transform: rotate(135deg);
  }
  
  .hamburger-menu.open span:nth-child(2) {
	background-color: $white;
	opacity: 0;
	left: -60px;
  }
  
  .hamburger-menu.open span:nth-child(3) {
	background-color: $white;
	top: 0.6rem;
	-webkit-transform: rotate(-135deg);
	-moz-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	transform: rotate(-135deg);
  }
  