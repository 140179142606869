.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1050;
    top: 0;
    left: 0;
    background-color: #1d1d1d;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
    @include breakpoint(ipad-max) {
      padding-top: 1rem;
      border-top: 1px solid $white;
      overflow-y: auto;
      z-index: 100000000;
    }
    .nav-item {
      &:hover,
      &.active {
        .nav-link {
          color: #f1f1f1;
        }
      }
      .nav-link {
          padding: 0.8rem 0.8rem 0.8rem 2rem;
          text-decoration: none;
          font-size: 1.4rem;
          color: #818181;
          display: block;
          transition: 0.3s;
          white-space: nowrap;
          @include breakpoint(ipad-max) {
            font-size: 2rem;
            padding: 2rem 7rem;
          }
          @include breakpoint(mobile-max) {
            padding: 1rem 2rem;    
        }
      }
    }
  }

.hamburger-menu {
  cursor: pointer;
  position: absolute;
  left: 2.2rem;
  top: 2.2rem;
  z-index: 9999;
  @include breakpoint (ipad-max) {
    display: none;
  }
  span {
    background-color: #5a5a5a;
    width: 2rem;
    height: 0.3rem;
    display: block;
    margin-bottom: 0.3rem;
    // &:nth-child(2) {
    //   height: 0.2rem;
    // }
  }
}
.open-sidebar {
  width: 16rem;
}
.open-sidebar-mob {
  width: 100%;
}
.shift-main {
  padding-left: 16rem;
  transition: padding-left .5s;
}
.menu-mobile {
  background-color: #1d1d1d;
  padding: 2rem 10rem 2rem 7rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1500;
  @include breakpoint(mobile-max) {
      padding: 2rem;    
  }
  &__img {
    width: 3rem;
    height: 3rem;
  }
  &__social {
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      font-size: 2rem;
      color: $white;
      text-decoration: none;
      &:hover {
        text-decoration: none;
      }
    }
  }
  @include breakpoint(desktop-min) {
    display: none;
  }
}
